/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearcom-announces-postponement-of-its-initial-public-offering",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearcom-announces-postponement-of-its-initial-public-offering",
    "aria-label": "hearcom announces postponement of its initial public offering permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "hear.com Announces Postponement of Its Initial Public Offering"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "UTRECHT, THE NETHERLANDS, May 13, 2021 – hear.com N.V., the largest online provider of expert, medical-grade hearing care globally, today announced that it has postponed plans for its initial public offering due to current challenging equity market conditions. The company will continue to monitor the market for more stable conditions."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Morgan Stanley and J.P. Morgan are acting as joint lead book-running managers for the proposed offering and as representatives of the underwriters for the proposed offering. Deutsche Bank Securities and Goldman Sachs & Co. LLC are acting as active book-running managers. BofA Securities, William Blair, and Truist Securities are also acting as book-running managers for the proposed offering."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A registration statement on Form F-1, including a prospectus, relating to these securities has been filed with the Securities and Exchange Commission (the “SEC”), but has not yet been declared effective. These Securities may not be sold nor may offers to buy be accepted until the Registration Statement becomes effective. This press release shall not constitute an offer to sell or a solicitation of an offer to buy these securities, nor shall there be any sale of these securities in any state or jurisdiction in which such offer, solicitation or sale would be unlawful prior to registration or qualification under the securities laws of any such state or jurisdiction."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The proposed offering will be made only by means of a prospectus. Copies of the prospectus may be obtained, when available, from the SEC’s website or from: Morgan Stanley & Co. LLC, Prospectus Department, 180 Varick Street, New York, NY 10014, or by email to prospectus@morganstanley.com; or J.P. Morgan Securities LLC, via Broadridge Financial Solutions, 1155 Long Island Avenue, Edgewood, New York 11717; or Deutsche Bank Securities Inc., Prospectus Group, 60 Wall Street, New York, NY 10005-2836, telephone (800) 503-4611 or by emailing prospectus.CPDG@db.com; or Goldman Sachs & Co. LLC, Prospectus Department, 200 West Street, New York, NY 10282, telephone: 1-866-471-2526, facsimile: 212-902-9316 or by emailing Prospectus-ny@ny.email.gs.com."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "About hear.com")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com is on a mission to bring high quality hearing care to anyone, anywhere. Founded in 2012 and headquartered in the Netherlands, hear.com has grown to become the largest online provider of expert, medical-grade hearing care globally. hear.com has helped millions get on the path to better hearing with its Hearing Success Program", React.createElement("sup", null, "®"), "."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Investor Relations:")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Jack Smet"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Head of Investor Relations"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "jack.smet@hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Media Contact:")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Daniel Yunger / Maddie Sewani"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Kekst CNC"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "daniel.yunger@kekstcnc.com / maddie.sewani@kekstcnc.com"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
